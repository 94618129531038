

/* ******************* custom drafjs css */


  
  .wrapper-class {
    background-color: white;
    margin-left:10px;
    margin-right: 10px;
    
  }
  
  .editor-class {
    border: 1px solid #e8e8e8;
    background-color: white;
    max-height: 45vh;
    min-height: 45vh;
    overflow: auto;
    color: gray;
    border-radius: 10px;
    padding-left: 10px;
    line-height: 5px;
    /* margin-right: 110px; */
  
  }
  
  /* .editor-class :focus {
    color: #1A1E29;
    background-color: white;
    max-height: 85px;
    min-height: 85px;
    overflow: hidden;
    border-radius: 10px;
    padding: 5px;
  }
   */
  
  
  .toolbar-class {
      
    border: 1px solid #ccc;
    background-color: #1A1E29;
    border-color: transparent;
  
    
  }

  /* .rdw-editor-toolbar {
    background-color: #1A1E29;
  } */