/* html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu,
    roboto, noto, segoe ui, arial, sans-serif;
  background: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

#root {
  overflow: hidden;
  background: #f0f0f0;
} */

.card1,
.card2,
.card3,
.card4 {
  position: absolute;
  border-radius: 5px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  will-change: transform;
}

.card1 {
  min-width: 250px;
  min-height: 250px;
  margin-left: -250px;
  width: 250px;
  height: 250px;
  max-width: 250px;
  max-height: 250px;
  background-image: url(https://image.flaticon.com/icons/svg/119/119596.svg);
}

.card2 {
  margin-left: -100px;
  margin-top: 200px;
  margin-right: 30px;
  width: 120px;
  height: 120px;
  background-image: url(https://image.flaticon.com/icons/svg/789/789395.svg);
}

.card3 {
  margin-left: -100px;
  opacity: 0.9;
  margin-top: 250px;
  width: 120px;
  height: 120px;
  background-image: url(https://image.flaticon.com/icons/svg/414/414927.svg);
}

.card4 {
  margin-left: -200px;
  margin-top: 150px;
  margin-right: 150px;
  width: 120px;
  height: 120px;
  background-image: url(https://image.flaticon.com/icons/svg/789/789392.svg);
}

.container {
  width: 250px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
